import React from 'react'

export const InfoPage = () => {
  return (
	  <section className='h-screen z-10 relative'>
		  <div className='w-full h-screen bg-cover fon bg-center'></div>
		  <article className='container z-10 relative'>
			  <h1>InfoPage</h1>
		  </article>
	 </section>
  )
}
