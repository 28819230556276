import './App.css'
import 'antd/dist/reset.css'
import { Header } from './components/header/Header'
import { ConfigProvider, FloatButton } from 'antd'
import locale from 'antd/es/locale/ru_RU'
import { motion, useScroll } from "framer-motion"
import { MainPage } from './pages/MainPage'
import ErrorPage from './pages/errorPage/ErrorPage'
import { FooterSection } from './components/footerSection/FooterSection'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { InfoPage } from './pages/infoPage/InfoPage'

function App() {
  const { scrollYProgress } = useScroll()
  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          colorPrimary: '#ea580c',
        },
      }}
    >

      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />
      <BrowserRouter>
        <div className='app'>
          <Header />

          <main>
            <Routes>
              <Route path='/' element={<MainPage />} />
              <Route path='/info' element={<InfoPage />} />
              <Route path='*' element={<ErrorPage />} />
            </Routes>
          </main>

          <FooterSection />
        </div>

      </BrowserRouter>
      

      <FloatButton.BackTop />
    </ConfigProvider>
  )
}

export default App
