import React, { useState } from 'react'
import {
	PercentageOutlined, UsergroupAddOutlined,
	FormOutlined, DollarCircleOutlined,
	GiftOutlined, NotificationOutlined
} from '@ant-design/icons'
import { Button, notification } from 'antd'
import { ModalComp } from '../components/modal/ModalComp'
import { PriceSection } from '../components/priceSection/PriceSection'
import { FotoSlider } from '../components/fotoSliderSection/FotoSlider'
import { OtzyvySection2 } from '../components/otzyvySection/OtzyvySection2'
import { FooterSection } from '../components/footerSection/FooterSection'
import VoprosOtvet from '../components/voprosOtvet/VoprosOtvet'
import { InfoSection } from '../components/infoSection/InfoSection'

export const MainPage = () => {

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [title, setTitle] = useState('')
	const [isActive, setIsActive] = useState({
		question: false,
		sale: false,
		date:false
	
	})
	const showModal = (title, active) => {
		setIsModalOpen(true)
		setTitle(title)
		if (active === "question") {
			setIsActive(prev => ({ ...prev, question: true, sale: false, date: false }))
		}
		if (active === "sale") {
			setIsActive(prev => ({ ...prev, sale: true, question: false, date: false }))
		}
		if (active === "date") {
			setIsActive(prev => ({ ...prev, sale: false, question: false, date: true,  }))
		}
	
	}
	const handleCancel = () => {
		setIsModalOpen(false)
	}
	// const [api, contextHolder] = notification.useNotification()
	// const openNotificationWithIcon = (type, key) => {
	// 	if (key === 1) {
	// 		api[type](
	// 			{
	// 				message: 'Предоставляем скидку 20%',
	// 				description: (
	// 					<div>
	// 						<ul className='list-disc'>
	// 							<li>
	// 								Пенсионерам
	// 							</li>
	// 							<li>
	// 								Инвалидам
	// 							</li>
	// 							<li>
	// 								Многодетным семьям
	// 							</li>
	// 						</ul>
	// 					</div>
	// 				)
	// 			},
	// 		)
	// 	} else {
	// 		api[type](
	// 			{
	// 				message: 'Акция',
	// 				description: (

	// 					<div className='mt-2'>
	// 						<FormOutlined />
	// 						{' '}Запишитесь заранее и получите скидку 15%
	// 					</div>
	// 				)
	// 			},
	// 		)
	// 	}
	// }

	return (
		<>
			<section className='w-full z-10 text-center' id='main'>
				<div className='w-full h-screen bg-cover fon bg-center'></div>

				<div className='container z-10 relative flex flex-col justify-between items-center'>
					<div className='pt-10'>
						<h1 className='xy:text-3xl sd:text-7xl font-semibold text-white mb-2 tracking-wide uppercase'>
							Ремонт бытовой техники в Минске
						</h1>
						<span className='text-orange-600 text-lg md:text-4xl tracking-widest'>
							с выездом к клиенту
						</span>
						<p className='text-white uppercase text-[10px] mt-3'>
							выезд в течении часа
						</p>
					</div>

					<div className='w-full mt-10 mb-20'>
						<span
							className='uppercase sd:text-2xl xy:text-sm text-white cursor-pointer border px-3 py-2'
							onClick={() => showModal('Задать вопрос', "question")}
						>
							Задать вопрос
						</span>
					</div>

					<div className='flex xy:justify-between w-full'>
						<div className='flex justify-start items-center text-orange-600 sd:text-lg xy:text-xs xy:mr-1 sd:mr-10'>
							<Button
								icon={<PercentageOutlined className='sd:text-lg xy:text-xs text-white' />}
								type='primary'
								shape="circle"
							/>
							<span
								className='font-light ml-2'
								onClick={() => showModal('Скидка за предаварительную запись 15%', "date")}
							>
								Получите скидку 15%
							</span>
						</div>
						<div className='flex justify-start items-center sd:text-lg xy:text-xs text-orange-600'>
							<Button
								icon={<DollarCircleOutlined className='sd:text-lg xy:text-xs text-white' />}
								type='primary'
								shape="circle"
							/>
							<span
								className='font-light ml-2'
								onClick={() => showModal('Получить скидку 20%', "sale")}
							>
								Скидка 20%
							</span>
						</div>
					</div>

					<div className='mt-20'>
						<p className='text-orange-600 uppercase text-sm'>
							Гарантируем качество, аккуратность, чистоту и приятную цену
						</p>
					</div>
				</div>



				<ModalComp isModalOpen={isModalOpen} title={title} handleCancel={handleCancel} isActive={isActive} />
			</section>


			<PriceSection />
			<InfoSection />

			<FotoSlider />

			<OtzyvySection2 />

			<VoprosOtvet />


		</>
	)
}
