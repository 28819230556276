import React from 'react'
import { Collapse } from 'antd'
import { useScreens } from '../../Constants/constants'
import { data } from '../../Constants/voprosOtvet/dataVoprosOtvet'
const { Panel } = Collapse





const CollapseComp = () => {
	const screens = useScreens()
	return (
		<Collapse ghost>
			{data.map(el => {
				return (
					<Panel header={<span className=''>{el.title}</span>} key={el.id} >
						<div className={`${screens.xs ? 'pl-2' : 'pl-6'}`}>
							<span className=''>{el.text}</span>
						</div>
					</Panel>
				)
			})}
		</Collapse>
	)
}
export default CollapseComp