import React from 'react'
import { Image, Collapse } from 'antd'
import img from '../../images/price/4.webp'
import img2 from '../../images/price/6.webp'
import img3 from '../../images/price/8.webp'

const { Panel } = Collapse

export const PriceSection = () => {
	return (
		<section className='py-10 w-full z-10 bg-white min-h-screen mt-20 relative' id='price'>
			<div className='container'>
				<div className='text-center uppercase xy:text-2xl font-semibold flex justify-center'>
					<span className=''>
						Лучшая
					</span>
					<h2 className='text-orange-600 ml-1 font-semibold'>
						цена
					</h2>
				</div>


				<div className='text-center uppercase'>
					<p className='text-[10px] font-light'>
						В 20% случаев, проблема решается по телефону с помощью <span className='font-semibold'>БЕСПЛАТНОЙ</span> консультации.
					</p>
					<p className='text-[10px] font-light'>
						В 96% случаев, мастер производит ремонт на месте в день обращения.
					</p>
				</div>

				<div className='text-center mt-10 py-4 px-1 bg-slate-200'>
					<h2 className='uppercase mb-4'>
						Ремонт холодильника
					</h2>
					<Image src={img} width='40px' preview={false} />
					<h3 className='font-light uppercase text-xs mb-1 mt-4'>
						Стоимость ремонта холодильника
					</h3>
					<p className='mt-0 mb-3 font-light'>
						от 17 руб
					</p>
					<p className='mt-2 text-[10px] font-light mb-1 uppercase'>
						Диагностика неисправности БЕСПЛАТНО*
					</p>
					<p className='mt-1 text-xs font-light'>
						Гарантия до 2-х лет
					</p>
					<div className='text-left mt-5 px-4'>

						<ul className='text-sm tracking-wide'>
							<li className='mb-2 flex justify-between pb-2 border-b border-gray-300'>
								<span className=''>Не холодит</span> <span className='' >от 23 руб</span>
							</li>
							<li className='mb-2 flex justify-between pb-2 border-b border-gray-300'>
								<span className=''>Не морозит</span> <span className='' >от 23 руб</span>
							</li>
							<li className='mb-2 flex justify-between pb-2 border-b border-gray-300'>
								<span className=''> Не включается</span> <span className='' >от 19 руб</span>
							</li>
							<li className='mb-2 flex justify-between pb-2 border-b border-gray-300'>
								<span className=''> Не отключается</span> <span className='' >от 20 руб</span>
							</li>
							<li className='mb-2 flex justify-between pb-2 border-b border-gray-300'>
								<span className=''> Намерзает</span> <span className='' >от 21 руб</span>
							</li>
							<li className='mb-2 flex justify-between'>
								<span className=''> Шумит</span> <span className='' >от 17 руб</span>
							</li>
						</ul>
					</div>
				</div>



				<div className='text-center mt-10 py-4 px-1 bg-slate-200'>
					<h2 className='uppercase mb-4'>
						Ремонт стиральной машины
					</h2>
					<Image src={img2} width='40px' preview={false} />
					<h3 className='font-light uppercase text-xs mb-1 mt-4'>
						Стоимость ремонта стиральной машины
					</h3>
					<p className='mt-0 mb-3 font-light'>
						от 18 руб
					</p>
					<p className='mt-2 text-[10px] font-light mb-1 uppercase'>
						Диагностика неисправности БЕСПЛАТНО*
					</p>
					<p className='mt-1 text-xs font-light'>
						Гарантия до 2-х лет
					</p>
					<div className='text-left mt-5 px-4'>

						<ul className='text-sm tracking-wide'>
							<li className='mb-2 flex justify-between pb-2 border-b border-gray-300'>
								<span className=''>
									Не греет воду
								</span>
								<span className='' >
									от 20 руб
								</span>
							</li>
							<li className='mb-2 flex justify-between pb-2 border-b border-gray-300'>
								<span className=''>
									Не сливает
								</span>
								<span className='' >
									от 18 руб
								</span>
							</li>
							<li className='mb-2 flex justify-between pb-2 border-b border-gray-300'>
								<span className=''>
									Шумит
								</span>
								<span className='' >
									от 21 руб
								</span>
							</li>
							<li className='mb-2 flex justify-between pb-2 border-b border-gray-300'>
								<span className=''>
									Не крутит барабан
								</span>
								<span className='' >
									от 25 руб
								</span>
							</li>
							<li className='mb-2 flex justify-between pb-2 border-b border-gray-300'>
								<span className=''>
									Прыгает
								</span>
								<span className='' >
									от 19 руб
								</span>
							</li>
							<li className='mb-2 flex justify-between'>
								<span className=''>
									Не начинает стирку
								</span>
								<span className='' >
									от 20 руб
								</span>
							</li>
						</ul>
					</div>
				</div>



				<div className='text-center mt-10 py-4 px-1 bg-slate-200'>
					<h2 className='uppercase mb-4'>
						Ремонт посудомоечной машины
					</h2>
					<Image src={img3} width='45px' preview={false} />
					<h3 className='font-light uppercase text-xs mb-1 mt-4'>
						Стоимость ремонта посудомоечной машины
					</h3>
					<p className='mt-0 mb-3 font-light'>
						от 15 руб
					</p>
					<p className='mt-2 text-[10px] font-light mb-1 uppercase'>
						Диагностика неисправности БЕСПЛАТНО*
					</p>
					<p className='mt-1 text-xs font-light'>
						Гарантия до 2-х лет
					</p>
					<div className='text-left mt-5 px-4'>
						{/* <Collapse accordion size='small'>
							<Panel header={
								<div className='flex justify-between'>
									<span>
										Не включается
									</span>
									<span>
										от 35 руб
									</span>
								</div>
							}
								key="1"
							>
								<div className='flex justify-between text-xs mb-1'>
									<span className=''>
										Неисправен сетевой шнур
									</span>
									<span className=''>
										35 руб
									</span>
								</div>
								<div className='flex justify-between text-xs mb-1'>
									<span className=''>
										Повреждение электропроводки
									</span>
									<span className=''>
										45 руб
									</span>
								</div>
								<div className='flex justify-between text-xs mb-1'>
									<span className=''>
										Неисправна кнопка включения
									</span>
									<span className=''>
										45 руб
									</span>
								</div>
							</Panel>
							<Panel header={
								<div className='flex justify-between'>
									<span>
										Не греет
									</span>
									<span>
										от 40 руб
									</span>
								</div>
							} key="2">
								<div className='flex justify-between text-xs mb-1'>
									<span className=''>
										Неисправен датчик температуры
									</span>
									<span className=''>
										70 руб
									</span>
								</div>
								<div className='flex justify-between text-xs mb-1'>
									<span className=''>
										Неисправен ТЭН (нагреватель)
									</span>
									<span className=''>
										90 руб
									</span>
								</div>
								<div className='flex justify-between text-xs mb-1'>
									<span className=''>
										Неисправна эл. проводка
									</span>
									<span className=''>
										45 руб
									</span>
								</div>
							</Panel>
							<Panel header={
								<div className='flex justify-between'>
									<span>
										Плохо моет посуду
									</span>
									<span>
										от 45 руб
									</span>
								</div>
							} key="3">
								<div className='flex justify-between text-xs mb-1'>
									<span className=''>
										Отсутствие нагрева воды
									</span>
									<span className=''>
										90 руб
									</span>
								</div>
								<div className='flex justify-between text-xs mb-1'>
									<span className=''>
										Неисправен отсек моющих средств
									</span>
									<span className=''>
										65 руб
									</span>
								</div>
								<div className='flex justify-between text-xs mb-1'>
									<span className=''>
										Засор распылителя воды
									</span>
									<span className=''>
										45 руб
									</span>
								</div>
							</Panel>
							<Panel header={
								<div className='flex justify-between'>
									<span>
										Протекает вода
									</span>
									<span>
										от 65 руб
									</span>
								</div>
							} key="4">
								<div className='flex justify-between text-xs mb-1'>
									<span className=''>
										Повреждение уплотнителя двери
									</span>
									<span className=''>
										65 руб
									</span>
								</div>
								<div className='flex justify-between text-xs mb-1'>
									<span className=''>
										Повреждён шланг
									</span>
									<span className=''>
										75 руб
									</span>
								</div>
								<div className='flex justify-between text-xs mb-1'>
									<span className=''>
										Повреждение сальника насоса
									</span>
									<span className=''>
										105 руб
									</span>
								</div>
								<div className='flex justify-between text-xs mb-1'>
									<span className=''>
										Провреждены внутренние патрубки
									</span>
									<span className=''>
										65 руб
									</span>
								</div>
							</Panel>
						</Collapse> */}

						<ul className='text-sm tracking-wide'>
							<li className='mb-2 flex justify-between pb-2 border-b border-gray-300'>
								<span className=''>
									Не включается
								</span>
								<span className='' >
									от 20 руб
								</span>
							</li>
							<li className='mb-2 flex justify-between pb-2 border-b border-gray-300'>
								<span className=''>
									Не сливает
								</span>
								<span className='' >
									от 18 руб
								</span>
							</li>
							<li className='mb-2 flex justify-between pb-2 border-b border-gray-300'>
								<span className=''>
									Плохо моет
								</span>
								<span className='' >
									от 21 руб
								</span>
							</li>
							<li className='mb-2 flex justify-between pb-2 border-b border-gray-300'>
								<span className=''>
									Не заливает воду
								</span>
								<span className='' >
									от 15 руб
								</span>
							</li>
							<li className='mb-2 flex justify-between'>
								<span className=''>
									Не сушит
								</span>
								<span className='' >
									от 29 руб
								</span>
							</li>

						</ul>
					</div>
				</div>

				<p className='font-extralight text-xs mt-5'>
				* Диагностика неисправности БЕСПЛАТНО в случае дальнейшего ремонта.
				</p>
			</div>
		</section>
	)
}
